import HomepageBanner from '@/components/scripts/Main/HomepageBanner/index.vue'
import ExcellentRating from '@/components/scripts/ExcellentRating/index.vue'
import GlassesTypes from '@/components/scripts/Main/GlassesTypes/index.vue'
import Sales from '@/components/scripts/Main/Sales/index.vue'
import BrandsCarousel from '@/components/scripts/BrandsCarousel/index.vue'
import StaffPicks from '@/components/scripts/Main/StaffPicks/index.vue'
import Guides from '@/components/scripts/Main/Guides/index.vue'
import NavAccordion from '@/components/scripts/Main/NavAccordion/index.vue'
import More from '@/components/scripts/Main/More/index.vue'
import AdvantagesList from '@/components/scripts/Main/AdvantagesList/index.vue'
import TrustpilotWidget from '@/components/scripts/TrustpilotWidget/index.vue'
import { mapState } from 'vuex'

export default {
  name: 'mainPage',
  components: {
    HomepageBanner,
    ExcellentRating,
    GlassesTypes,
    NavAccordion,
    More,
    Sales,
    BrandsCarousel,
    StaffPicks,
    Guides,
    AdvantagesList,
    TrustpilotWidget
  },
  mounted () {
    let router = this.$route
    let link = router.path
    if (this.lang) {
      link = link.replace(new RegExp('^/' + this.lang + '($|/)', 'g'), '/').replace(/(\/){2,}/, '/')
    }
    this.$store.dispatch('getMenuDescription', [{
      link: link
    }, router])
  },
  asyncData ({store}) {
    return Promise.all([
      store.dispatch('getBlocks', 'main'),
      store.dispatch('getBlocks', 'grey'),
      store.dispatch('fetchUrlBatchBySID', [
        'prescription_glasses_mens',
        'prescription_glasses_ladies',
        'cheap'
      ])
    ])
  },
  computed: {
    ...mapState({
      meta: (state) => ({
        ...state.formMeta
      }),
      loading: (state) => (state.loading)
    })
  }
}
